<template>
  <div class="main-content white">
    <div class="content-block">
      <div class="notify-content-block">
        <div class="notify-title">{{ notification.title }}</div>
        <div class="notify-time">{{ notification.created_at }}</div>
        <div class="notify-content" v-html="notification.content"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { showNotification } from '@/api/notifications';
export default {
  data() {
    return {
      notification: {}
    };
  },
  watch: {
    $route: {
      immediate: true,
      handler(to, from) {
        showNotification(to.params.notification_id).then(data => {
          this.notification = data;
        });
      }
      // react to route changes...
    }
  }
};
</script>
